<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class="pb-5">
          <template v-slot:headerTitle>
            <h3 class="card-title font-weight-bold" style="font-size: 30px">Crear Registro</h3>
            <h5 class="card-title font-weight-bolder mt-4 mb-2" style="font-size: 20px">General</h5>
            <p style="font-size: 1rem">Aquí puede introducir la información general del Movimiento, como la fecha, la
              moneda y la descripción.</p>
          </template>
          <form class="p-4" @submit.prevent="saveMovimiento">
            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <label for="Fecha">Fecha *</label>
                <b-form-input type="date" id="Fecha" name="Fecha" aria-describedby="input-live-help input-live-feedback"
                  placeholder="Ingrese Fecha" required trim></b-form-input>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label for="Divisa">Divisa *</label>
                <div class="d-flex">
                  <b-form-select name="Divisa" placeholder="-- Selecionar la Divisa --" :options="ListDivisa" required
                    value="null">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Selecionar la Divisa --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <!-- <vs-button class='ml-2 m-0' icon @click="$bvModal.show('bv-modal-divisa')"><ion-icon
                      style='font-size: 1.3rem' name="add-outline"></ion-icon></vs-button> -->
                </div>
              </div>
              <div class="col-12 mt-3">
                <label for="Descripcion">Descripción *</label>
                <b-form-textarea id="Descripcion" name="Descripcion"
                  aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Descripción" rows="6"
                  required trim></b-form-textarea>
              </div>
            </div>
            <p style="color: red" class="mt-3">{{ error }}</p>
            <div class="pt-4">
              <h5 class="card-title font-weight-bolder mt-4 mb-2" style="font-size: 20px">Movimientos</h5>
              <p style="font-size: 1rem">Aquí puede introducir los Movimiento, como cuenta, cargo, abono, etc.</p>
              <hr style="height: 1px; background: var(--dark)" class="w-100" />
            </div>
            <vs-table>
              <template #thead>
                <vs-tr style="background: var(--body-bg) !important">
                  <vs-th style="min-width: 270px !important; background: var(--body-bg) !important"> Cuenta </vs-th>
                  <vs-th style="min-width: 280px !important; background: var(--body-bg) !important"> Nota </vs-th>
                  <vs-th style="min-width: 10rem; background: var(--body-bg) !important"> Débito </vs-th>
                  <vs-th style="min-width: 10rem; background: var(--body-bg) !important"> Crédito </vs-th>
                  <vs-th style="min-width: 3rem; background: var(--body-bg) !important"> Opciones </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr v-for="(movi, index) in movimientos" :key="index">
                  <vs-td style="z-index: 3px !important">
                    <!-- <b-form-select v-model='movi.cuentaID' placeholder='-- Selecionar la Cuenta --'
                      :options="ListCuenta" required value='null'>
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Selecionar la Cuenta --</b-form-select-option>
                      </template>
    </b-form-select> -->
                    <v-select append-to-body :reduce="(option) => option.value" label="text" v-model="movi.cuentaID"
                      required placeholder="-- Selecionar la Cuenta --" :options="ListCuenta">
                    </v-select>
                  </vs-td>
                  <vs-td>
                    <b-form-input v-model="movi.nota" aria-describedby="input-live-help input-live-feedback"
                      placeholder="Ingrese" required trim></b-form-input>
                  </vs-td>
                  <vs-td>
                    <b-form-input type="number" v-model="movi.debito" :disabled="movi.credito > 0" placeholder="0"
                      min="1"></b-form-input>
                  </vs-td>
                  <vs-td>
                    <b-form-input type="number" v-model="movi.credito" :disabled="movi.debito > 0" placeholder="0"
                      min="0" step="1"></b-form-input>
                  </vs-td>
                  <vs-td>
                    <vs-button class="bg-danger" @click.prevent="eliminarArticulo(index)">
                      <ion-icon name="trash-outline"></ion-icon>
                    </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
              <template #notFound>
                <vs-tr class="d-flex justify-content-center"> No hay Artículos </vs-tr>
              </template>
              <template #footer>
                <div class="d-flex justify-content-center">
                  <vs-button class="d-flex" style="gap: 1rem" primary @click.prevent="agregarMovimiento"><ion-icon
                      style="font-size: 1.6rem" name="add-outline"></ion-icon> Agregar un Movimiento</vs-button>
                </div>
              </template>
            </vs-table>
            <hr style="height: 1px; background: var(--dark)" class="w-100" />
            <div v-for="item of ListaEvidencias" :key="item.id">
             
              <div class="d-flex justify-content-end">
                <vs-button @click.prevent="eliminarEvidencia(item)" class="d-flex " style="margin-bottom: -2.5rem;"
                  danger><i class="fa-solid fa-xmark p-0" style="font-size: 1.1rem;"></i></vs-button>
              </div>
              <DropFile  :urlDir="`empresa/${persona.empresa.id}/evidencias/registrosContables/`+RegistroContableId"
                :pathGet="`RegistroContableEvidencias/ListRegistroContableEvidencias/${RegistroContableId}`" pathPost="RegistroContableEvidencias/CrearRegistroContableEvidencias" :injectData="{
                  NombreArchivo: 'rcontable',
                  Url: `https://helexiumfiles.com/empresa/${persona.empresa.id}/evidencias/registrosContables/`+RegistroContableId,
                  RegistroContableId: RegistroContableId,
                  Fecha: $moment
                    .tz(new Date(), 'America/Bogota')
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
                }" :fileTypes="[
                  'application/pdf',
                  'application/docx',
                  'image/jpeg',
                  'image/png',
                  'image/gif',
                  'application/msword',
                  'application/vnd.ms-excel',
                  'application/vnd.ms-powerpoint',
                  'text/plain',
                  'image/*',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'application/vnd.ms-powerpoint',
                  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.ms-excel',
                ]" />


            </div>

            <div class="d-flex justify-content-center">
              <vs-button class="d-flex" primary @click.prevent="agregarEvidenciaALista()"><ion-icon
                  style="font-size: 1.6rem" name="add-outline"></ion-icon> Agregar evidencia</vs-button>
                  <!-- boton temporal -->
              <!-- <vs-button class="d-flex mx-1" primary @click.prevent="saveData()"><ion-icon style="font-size: 1.6rem"
                  name="add-outline"></ion-icon> guardar</vs-button> -->
            </div>


            <hr style='height: 1px; background: var(--dark);' class='w-100' />

            <div class="row pt-3 px-2 justify-content-end align-items-center">
              <h3 class="col-2 py-3 text-right">Subtotal</h3>
              <p class="col-2 mb-0 py-2" style="font-size: 1.2rem">${{ Totaldebito }}</p>
              <p class="col-2 mb-0 py-2" style="font-size: 1.2rem">${{ Totalcredito }}</p>
            </div>
            <div class="row  pt-0 px-2 justify-content-end align-items-center rounded">
              <h3 class="col-2 py-3 text-right">Total</h3>
              <template>
                <span v-if="Totaldebito === Totalcredito || Totaldebito > Totalcredito" class="col-2 mb-0 bg-success rounded-left py-2  my-0"
                  style="font-size: 1.2rem">$ {{ Totaldebito }}</span>
                <span v-if="Totaldebito < Totalcredito" class="col-2 my-0 bg-danger rounded-left py-2" style="font-size: 1.2rem">$ {{
                  Totaldebito - Totalcredito }}</span>
              </template>
              <template>
                <span v-if="Totaldebito === Totalcredito || Totalcredito > Totaldebito" class="col-2 mb-0 bg-success rounded-right py-2  my-0"
                  style="font-size: 1.2rem">$ {{ Totalcredito }}</span>
                <span v-if="Totalcredito < Totaldebito" class="col-2 my-0 bg-danger rounded-right py-2" style="font-size: 1.2rem">$ {{
                  Totalcredito - Totaldebito }}</span>
              </template>
            </div>
            <div class="d-flex pt-4 justify-content-end">
              <vs-button size="large" danger @click.prevent="$router.push('Registros')">Cancelar</vs-button>
              <vs-button size="large" :loading="waiting"
                :disabled="movimientos.length <= 1 || Totalcredito != Totaldebito">Guardar</vs-button>
            </div>
          </form>
          <b-modal id="bv-modal-divisa" hide-footer>
            <template #modal-title>
              <h4>Nueva Divisa</h4>
            </template>
            <form>
              <div>
                <label clas for="Nombre">Nombre *</label>
                <b-form-input id="Nombre" name="Nombre" aria-describedby="input-live-help input-live-feedback"
                  placeholder="Ingrese Nombre" required trim></b-form-input>
              </div>
              <div class="mt-3">
                <label clas for="Codigo">Código *</label>
                <b-form-input id="Codigo" name="Codigo" aria-describedby="input-live-help input-live-feedback"
                  placeholder="Ingrese Código" required trim></b-form-input>
              </div>
              <div class="mt-3">
                <label clas for="Cotizacion">Cotización *</label>
                <b-form-input id="Cotizacion" name="Cotizacion" aria-describedby="input-live-help input-live-feedback"
                  placeholder="Ingrese Cotización" required trim></b-form-input>
              </div>
            </form>
            <div class="d-flex mt-4 justify-content-end">
              <vs-button size="large" dark shadow @click="$bvModal.hide('bv-modal-divisa')">Cancelar</vs-button>
              <vs-button size="large" :loading="waiting">Guardar</vs-button>
            </div>
          </b-modal>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { eventBus } from "../../../../../../main";


import DropFile from "@/components/helexium/DropFileV3.vue";


import Swal from "sweetalert2";
import { core } from "../../../../../../config/pluginInit";
export default {
  components: {
    DropFile,
  },

  data: () => ({
    error: null,
    waiting: false,
    movimientos: [],
    ListDivisa: [],
    ListCuenta: [],
    groupedOptions: [],
    GuardarMovimiento: {
      fecha: null,
      idDivisa: null,
      descripcion: null,
      personaId: null,
      cuentasMovimiento: [],
    },
    ListaEvidencias: [],
    RegistroContableId: '',
    persona:{}
  }),
  async mounted() {
    const Persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.GuardarMovimiento.personaId = Persona.id;
    this.persona = Persona
    
    core.index();
    await this.getData();
  },
  watch: {
    movimientos() {
      this.movimientos;
    },
  },
  computed: {
    Totaldebito() {
      return this.movimientos.reduce((acc, movi) => acc + parseFloat(movi.debito), 0) || 0;
    },
    Totalcredito() {
      return this.movimientos.reduce((acc, movi) => acc + parseFloat(movi.credito), 0) || 0;
    },
  },
  methods: {
    saveData() {
      console.log('en savedata registro')
      eventBus.$emit("GuardandoEvidenciaRegistroCrear", true);
    },
    agregarEvidenciaALista() {
      this.ListaEvidencias.push({ id: this.ListaEvidencias.length + 1 })
    },
    eliminarEvidencia(item) {
      let posicionItem = this.ListaEvidencias.indexOf(item)
      this.ListaEvidencias.splice(posicionItem, 1)
      // this.$forceUpdate()
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `GDivisas/ObtenerDivisasEmpresa/${this.GuardarMovimiento.personaId}`,
        });
        let Cuentas = await this.$store.dispatch("hl_get", {
          path: `DESubCuenta/ObtenerInformacionCuentas/${this.GuardarMovimiento.personaId}`,
        });

        if (res.data.length > 0) {
          this.ListDivisa = res.data.map((item) => ({
            value: item.id,
            text: `${item.codigo} - ${item.nombre}`,
          }));
        } else {
          this.ListDivisa = [];
        }

        if (Cuentas.data.length > 0) {
          this.ListCuenta = Cuentas.data.flatMap((item) =>
            item.SubCuentas.map((sub) => ({
              text: sub.SubCuenta,
              value: sub.ID,
            }))
          );
        } else {
          this.ListCuenta = [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    eliminarArticulo(index) {
      this.movimientos.splice(index, 1);
    },
    agregarMovimiento() {
      this.movimientos.push({
        cuentaID: null,
        nota: "",
        debito: 0,
        credito: 0,
      });
    },
    async saveMovimiento(event) {
      const data = Object.fromEntries(new FormData(event.target).entries());

      if (data.Fecha === "" || data.Divisa === "" || data.Descripcion === "") {
        this.error = "Todos los campos son obligatorios";
        return;
      }

      this.GuardarMovimiento.fecha = this.$moment.tz(data.Fecha, "America/Bogota").format("YYYY-MM-DDTHH:mm");
      this.GuardarMovimiento.idDivisa = data.Divisa;
      this.GuardarMovimiento.descripcion = data.Descripcion;
      this.GuardarMovimiento.cuentasMovimiento = this.movimientos;

      this.GuardarMovimiento.cuentasMovimiento.map((item) => {
        item.debito = parseFloat(item.debito);
        item.credito = parseFloat(item.credito);
      });

      try {
        this.waiting = true;
        const response = await this.$store.getters.fetchPost({
          path: "DEMovimientos/HacerMovimiento",
          data: this.GuardarMovimiento,
        });
        let respuesta = await response.json()
        console.log('respu--->', respuesta.data)

        if (respuesta.respuesta) {
          this.RegistroContableId = respuesta.data
          await this.saveData()
          Swal.fire({
            title: "Movimiento Guardado",
            text: "Se ha guardado el Movimiento",
            icon: "success",
            confirmButtonText: "Aceptar",
          }).then(() => {
            this.$router.push("Registros");
            event.target.reset();
          });
        } else {
          throw new Error("No se ha podido guardar el Movimiento");
        }
      } catch (error) {
        console.log("erro al guarda el movimiento", error);
      }
    },
  },
};
</script>

<style scoped>
.vs__dropdown-option>h3 {
  background-color: black;
}

.vs-table__footer {
  background: var(--body-bg) !important;
}
</style>
<style>
.v-select .vs__dropdown-menu {
  position: fixed !important;
  z-index: 1000;
}

/* .vs__dropdown-menu {
    z-index: 40 !important;
  } */
.vs__dropdown-toggle {
  min-height: 2.2rem;
  height: auto;
}

.vs-table {
  min-height: 9rem;
}
</style>
